import { Project } from '@app/models/time-tracking/project.model';
import { Employee } from '@models/employee/employee.model';
import { ModelMixin } from '../core/base-generic.model';

export class TimeClockEntry extends ModelMixin<TimeClockEntry>() {
    protected static _type = 'timeTracking.timeClockEntries';
    protected static _resource = 'timeTracking/time-clock-entry';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['startTime', 'endTime', 'date', 'employeeId', 'projectId'];

    get startTime(): string {
        return this._attributes['startTime'];
    }

    set startTime(value: string) {
        this._attributes['startTime'] = value;
    }

    get endTime(): string | null {
        return this._attributes['endTime'];
    }

    set endTime(value: string | null) {
        this._attributes['endTime'] = value;
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    set employeeId(value: number) {
        this._attributes['employeeId'] = value;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get projectId(): number | null {
        return this._attributes['projectId'];
    }

    set projectId(value: number | null) {
        this._attributes['projectId'] = value;
    }

    get project(): Project | null {
        return this.hasOne(Project, 'project');
    }

    get date(): string {
        return this._attributes['date'];
    }

    set date(value: string) {
        this._attributes['date'] = value;
    }
}
